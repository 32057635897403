import { message, Upload } from 'antd';
import { getServerUrl } from '../Config';


export const uploadImageProps = {
  name: 'file',
  multiple: true,
  action: getServerUrl('/api/upload'),
  beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/webp' || file.type === 'image/gif' || file.type == 'video/mp4';
    if (!isJpgOrPng) {
      message.error(`You can only upload JPG/PNG/WEBP/GIF/MP4 file! File type[${file.type}]`);
      return Upload.LIST_IGNORE;
    }
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      message.error('Image must smaller than 10MB!');
      return Upload.LIST_IGNORE;
    }
    return true;
  }
};

export const uploadFileProps = {
  name: 'file',
  multiple: true,
  action: getServerUrl('/api/upload'),
  beforeUpload(file) {
    const isLt100M = file.size / 1024 / 1024 < 100;
    if (!isLt100M) {
      message.error('Image must smaller than 100MB!');
      return Upload.LIST_IGNORE;
    }
    return true;
  }
};


export const uploadBigFileProps = {
  name: 'file',
  multiple: true,
  action: getServerUrl('/api/upload'),
  beforeUpload(file) {
    const isLt1G = file.size / 1024 / 1024 / 1024 < 1;
    if (!isLt1G) {
      message.error('Image must smaller than 1G!');
      return Upload.LIST_IGNORE;
    }
    return true;
  }
};


export function isMobile() {
  const userAgentInfo = navigator.userAgent;
  const Agents = ["Android", "iPhone",
              "SymbianOS", "Windows Phone",
              "iPad", "iPod"];
  for (let v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) > 0) {
        return true;
      }
  }
  return false;
}