import React from 'react';


function EmptyPage()
{
    return (<div style={{margin:24}}>
        <h1>404 Page</h1>
    </div>);
}

export default EmptyPage;