import axios from 'axios';
import { getServerUrl } from '../Config';
import { fileToImageElementText } from './pinlog_edit.js';
export { deepCompare, fileToImageElementText, appendLogContent, insertLogContent, applyRule } from './pinlog_edit.js';
export { isFileImage } from './file_type.js';


function httpResponseToPinlogList(response) {
  if (response !== null) {
    const textList = response.data.data;
    const pinlogList = textList.map((one) => {
      return JSON.parse(one);
    })
    return pinlogList;
  }

  return null;
}


export async function queryAll() {
  const url = getServerUrl('/api/query_all.json');
  let [error, response] = await axios.get(url, { timeout: 10000 }).then(
    v => [null, v],
    err => [err, null],
  );

  return [error, httpResponseToPinlogList(response)];
}

export async function queryKeyword(keyword) {
  const url = getServerUrl('/api/query_keyword.json');
  let [error, response] = await axios.get(url, {
    params: {
      keyword
    },
    timeout: 10000
  }).then(
    v => [null, v],
    err => [err, null],
  );

  return [error, httpResponseToPinlogList(response)];
}

export async function queryId(pinId) {
  const url = getServerUrl(`/api/query_id.json?pin_id=${pinId}`);
  let [error, response] = await axios.get(url, { timeout: 10000 }).then(
    v => [null, v],
    err => [err, null],
  );

  if (response !== null && response.data.data !== null) {
    const pinlogText = response.data.data;
    return [error, JSON.parse(pinlogText)];
  }

  return [error, response];
}


export async function duplicatePinlog(pinId) {
  const url = getServerUrl(`/api/duplicate.json?pin_id=${pinId}`);
  let [error, response] = await axios.get(url, { timeout: 10000 }).then(
    v => [null, v],
    err => [err, null],
  );

  return [error, response];
}

export async function savePinlog(pinlog) {
  // 创建 pinlog 的深拷贝
  const pinlogCopy = JSON.parse(JSON.stringify(pinlog));

  // 检查并修改 revision
  if (pinlogCopy.pin.revision == null) {
    pinlogCopy.pin.revision = 1;
  } else {
    pinlogCopy.pin.revision += 1;
  }

  // 构建请求URL
  const url = getServerUrl('/api/modify.json');

  // 发送POST请求
  let [error, response] = await axios.post(url, pinlogCopy, { timeout: 10000 }).then(
    v => [null, v],
    err => [err, null],
  );

  // 返回结果
  return [error, response];
}

export async function createPinlog(pinTitle, pinAbstract, logContent) {
  const pinlog = {
    'pin': {
      'title': pinTitle,
      'abstract': pinAbstract,
    },
    'log': {
      'content': logContent
    }
  }

  const url = getServerUrl('/api/create.json');
  let [error, response] = await axios.post(url, pinlog, { timeout: 10000 }).then(
    v => [null, v],
    err => [err, null],
  );

  return [error, response];
}

export async function fetchAccessActivityRank() {
  const url = getServerUrl('/api/rank_access_activity.json');
  let [error, response] = await axios.get(url, { timeout: 10000 }).then(
    v => [null, v],
    err => [err, null],
  );

  if (response !== null && response.data.data !== null) {
    const rank = response.data.data;
    return [error, rank];
  }

  return [error, response];
}

export async function fetchUploadActivity() {
  const url = getServerUrl('/api/rank_upload_activity.json');
  let [error, response] = await axios.get(url, { timeout: 10000 }).then(
    v => [null, v],
    err => [err, null],
  );

  if (response !== null && response.data.data !== null) {
    const rank = response.data.data;
    return [error, rank];
  }

  return [error, response];
}

// ======
// 上传文件
// ======

export async function uploadFile(file) {
  const url = getServerUrl('/api/upload');
  let formData = new FormData();
  formData.append('file', file);

  let [error, response] = await axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    timeout: 10000
  }).then(
    v => [null, v],
    err => [err, null],
  );

  return [error, response];
}

export async function pasteImageAndUpload(e) {
  // 如果从 chrome 复制图片，有两个 item。第一个是 text/html，第二个是 image/png
  let imageItem = null;
  for (let item of e.clipboardData.items) {
    if (item.type.indexOf('image') >= 0) {
      imageItem = item;
      break
    }
  }
  if (imageItem === null) {
    return;
  }

  const imageFile = imageItem.getAsFile();
  const [error, response] = await uploadFile(imageFile);
  if (error !== null) {
    console.error(error);
    return;
  }
  if (response.data.code !== 0) {
    console.error(response.data);
    return;
  }
  const imageContent = fileToImageElementText(response.data.access_path);
  return imageContent;
}