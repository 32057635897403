import axios from 'axios';
import React, { useEffect, useState, createContext, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { message, Typography, Divider, Table, Button, DatePicker, Calendar, Space, Checkbox, Input, Modal, Upload } from 'antd';
import { getServerUrl } from '../Config';
import { queryAll, queryKeyword, createPinlog, fileToImageElementText, fetchAccessActivityRank } from '../DataModel';
import PinlogTable from '../PinlogTable';
import { InboxOutlined, PlusOutlined, PaperClipOutlined } from '@ant-design/icons';
import { uploadImageProps, uploadFileProps } from '../UI';
import './index.scss';

const { Title, Paragraph, Text, Link } = Typography;


const StreamPageContext = createContext(null);


async function requestKeyword(keyword) {
  const decoratedKeyword = keyword + ' !#HIDE#' + ' !#DELETE#';   // Web端查询时，排除标签，隐藏，删除
  let [error, pinlogList] = await queryKeyword(decoratedKeyword);

  if (error === null) {
    message.info('Query Success.');
  } else {
    console.error(error);
    message.error('Query Failed!');
  }

  return [error, pinlogList];
}

function TopButton() {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <div>
      <Space size='large'>
        <Button className='top-button' onClick={() => {
          message.error('not implement')
        }}>Title</Button>
        <Button className='top-button' onClick={() => {
          const title = searchParams.get('title') || '';
          const keyword = searchParams.get('keyword') || '';
          window.open(`/?title=${encodeURIComponent(title)}&keyword=${encodeURIComponent(keyword)}`, '_blank');
        }}>Clone</Button>
        <Button className='top-button' onClick={(e) => {
          window.open('/login','_blank');
        }}>Login</Button>
      </Space>
    </div>
  )
}

function TopPin() {
  const context = useContext(StreamPageContext);
  const [pinTitle, setPinTitle] = useState('');
  const [pinAbstract, setPinAbstract] = useState('');
  const [logContent, setLogContent] = useState('');

  const refresh = async function () {
    let [error, pinlogList] = await requestKeyword('');
    context.setViewPinlogList(pinlogList);
  }

  return (
    <div>
      <Divider>Pin</Divider>
      <div className='top-pin-row'>
        <Space>
          {/* 按钮 */}
          <Space direction='vertical' align='start'>
            {/* Pin Title */}
            <Button type='primary' className='pin-button' onClick={async () => {
              const [error, _] = await createPinlog(pinTitle, pinAbstract, logContent);
              if (error === null) {
                setPinTitle('');
                setPinAbstract('');
                setLogContent('');
                message.info('Pin Success.')
                // 刷新
                refresh();
              }
              else {
                console.error(error);
                message.error('Pin Failed!')
              }
            }}>
              Pin
            </Button>
            <Button className='refresh-button' onClick={refresh}>
              Refresh
            </Button>
          </Space>
          <div style={{ 'width': 10 }} />
          {/* 输入内容 */}
          <Space direction='vertical' align='start'>
            <Input style={{ 'width': 600 }} value={pinTitle} onChange={e => { setPinTitle(e.target.value) }} />
            <Input style={{ 'width': 600 }} value={pinAbstract} onChange={e => { setPinAbstract(e.target.value) }} />
            <Input.TextArea autoSize={{ minRows: 3 }} style={{ 'width': 600 }} value={logContent} onChange={e => { setLogContent(e.target.value) }} />
            <Space>
              {/* 上传图片 */}
              <div style={{ width: 200, height: 40 }}>
                <Upload.Dragger {...uploadImageProps} onChange={
                  (info) => {
                    const { status } = info.file;
                    if (status !== 'uploading') {
                      console.log(info.file, info.fileList);
                    }
                    if (status === 'done') {
                      // 插入到正文
                      const info_file = info.file;
                      const imageContent = fileToImageElementText(info_file.response.access_path);
                      setLogContent(logContent + '\n' + imageContent);
                      // 弹窗提示
                      message.success(`${info_file.name} file uploaded successfully.`);
                    } else if (status === 'error') {
                      message.error(`${info.file.name} file upload failed.`);
                    }
                  }}>
                  <p>
                    <InboxOutlined /> Image
                  </p>
                </Upload.Dragger>
              </div>
              {/* 上传文件 */}
              <div style={{ width: 200, height: 40 }}>
                <Upload.Dragger {...uploadFileProps} onChange={
                  (info) => {
                    const { status } = info.file;
                    if (status !== 'uploading') {
                      console.log(info.file, info.fileList);
                    }
                    if (status === 'done') {
                      // 插入到正文
                      const info_file = info.file;
                      const imageContent = `<a href="${info_file.response.access_path}" target="_blank">${info_file.name}</a>`;
                      setLogContent(logContent + '\n' + imageContent);
                      // 弹窗提示
                      message.success(`${info_file.name} file uploaded successfully.`);
                    } else if (status === 'error') {
                      message.error(`${info.file.name} file upload failed.`);
                    }
                  }}>
                  <p>
                    <InboxOutlined /> File
                  </p>
                </Upload.Dragger>
              </div>
            </Space>
          </Space>
        </Space>
        <RecentColumn />
      </div>
    </div>
  );
}

function TopFilter() {
  const [keyword, setKeyword] = useState("");
  const context = useContext(StreamPageContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const setKeywordAndSync = function (value) {
    setKeyword(value);
    const to = Object.assign({}, { 'title': searchParams.get('title'), 'keyword': value });
    setSearchParams(to);
  }
  // url 传参
  useEffect(() => {
    const inKeyword = searchParams.get('keyword');
    const inTitle = searchParams.get('title');
    if (inKeyword) {
      setKeywordAndSync(inKeyword);
    }
    if (inTitle) {
      document.title = `Pinlog - ${inTitle}`;
    }
  }, [])

  return (
    <div>
      <Divider>Filter</Divider>
      <Space>
        <Text strong>Keyword</Text>
        <Input value={keyword} onChange={e => {
          setKeywordAndSync(e.target.value);
        }}
          onPressEnter={async () => {
            const [_, pinlogList] = await requestKeyword(keyword);
            if (pinlogList !== null) {
              context.setViewPinlogList(pinlogList);
            }
          }} style={{ width: 280 }} />
        <Button type='primary' onClick={async () => {
          const [error, pinlogList] = await requestKeyword(keyword);
          context.setViewPinlogList(pinlogList);
        }}>
          Filter
        </Button>
        <Button onClick={() => { setKeywordAndSync('') }}>
          Reset
        </Button>
      </Space>
    </div>
  )
}

function RecentColumn() {
  const [activityList, setActivityList] = useState([]);
  // 刷新
  const refresh = async function () {
    const [error, fetchedList] = await fetchAccessActivityRank()
    if (error === null) {
      setActivityList(fetchedList.slice(0, 8));
    }
  }
  // 定时刷新
  useEffect(() => {
    refresh();
    const taskId = setInterval(refresh, 15 * 1000);
    return () => clearInterval(taskId);
  }, [])

  return (
    <div className='recently-column'>
      <Space>
        <Title level={4}>
          Recent&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <a href='recent' target='_blank'>
            <PlusOutlined />
          </a>
          &nbsp;&nbsp;
          <a href='upload_file' target='_blank'>
            <PaperClipOutlined />
          </a>
        </Title>
      </Space>
      {/* 2023-06-06。公开场合，显示最近文档标题，泄露隐私。考虑一级界面+开关，或者二级界面。
      <ol>
        {
          function () {
            let items = []
            for (let i = 0; i < activityList.length; ++i) {
              const one = activityList[i];

              items.push(
                <div key={one.pin_id}>
                  <li>
                    <a href={`edit/${one.pin_id}`} target='_blank'>
                      <Text>{one.title}</Text>
                    </a>
                  </li>
                </div>
              )
            }
            return items;
          }()
        }
      </ol> 
      */}
    </div>
  )
}

function StreamPage() {
  const [viewPinlogList, setViewPinlogList] = useState([]);

  return (<div style={{ margin: 24 }}>
    <StreamPageContext.Provider value={{
      setViewPinlogList,
    }}>
      <TopButton />
      <TopPin />
      <TopFilter />
      <Divider />
      <PinlogTable inputPinglogList={viewPinlogList} />
    </StreamPageContext.Provider>
  </div>);
}

export default StreamPage;