

const IMAGE_SUFFIX_LIST = [
  'jpeg',
  'jpg',
  'png',
  'gif',
  'webp',
]


export function isFileImage(filename) {
  const ext = filename.split('.').pop().toLowerCase();
  if (IMAGE_SUFFIX_LIST.includes(ext)) {
    return true;
  }
  return false;
}