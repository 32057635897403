import moment from 'moment';
import 'moment/locale/zh-cn';
import React, { useEffect, useState, createContext, useContext } from 'react';
import { Routes, Route, Outlet, useNavigate } from 'react-router-dom';
import { message, Typography, Divider, Table, Button, DatePicker, Calendar, Space, Checkbox, Input, Modal, Row, Col } from 'antd';
import { EditOutlined, CopyOutlined } from '@ant-design/icons';
import { duplicatePinlog } from '../DataModel';
import { isMobile } from '../UI';
import './index.scss';

const { Title, Paragraph, Text, Link } = Typography;

function gotoEditPage(pin_id)
{
  const url = `edit/${pin_id}`;
  window.open(url, '_blank');
}

const columns = [
  {
    title: 'Pin',
    dataIndex: 'pin',
    width: 200,
    render: (pin) => {
      return (<div onDoubleClick={()=> { gotoEditPage(pin.pin_id) }}>
        {moment(pin.pin_timestamp * 1000).format('YYYY-MM-DD, HH:mm:ss, dd')}
      </div>);
    },
  },
  {
    title: 'Pin Title',
    dataIndex: 'pin',
    render: pin => {
      return (<div onDoubleClick={()=> { gotoEditPage(pin.pin_id) }}>
        {pin.title}
      </div>);
    }
  },
  {
    title: 'Pin Abstract',
    dataIndex: 'pin',
    render: pin => {
      return pin.abstract;
    }
  },
  {
    title: 'Operation',
    dataIndex: 'pin',
    width:100,
    render: function Operation(pin) {
      const url = `edit/${pin.pin_id}`;
      // 移动端不显示复制按钮，防止误触
      // 2023-05-13，实际使用复制的场景非常少，且容易误触，所以改为默认不显示
      const shouldHideDuplicateButton = (true || isMobile()) ? 'hide-button' : '';

      return (
        <Space>
          <Link href={url} target="_blank"><EditOutlined /></Link>
          <Button type='link' className={`operation-button ${shouldHideDuplicateButton}`} onClick={() => {
            duplicatePinlog(pin.pin_id);
          }}>
            <CopyOutlined />
          </Button>
        </Space>
      )
    }
  }
]


function PinlogTable(props) {
  const { inputPinglogList } = props;
  const navigate = useNavigate();
  return (
    <div>
        <Table columns={columns} dataSource={inputPinglogList} rowKey={one => one.pin.pin_id} />
    </div>
  )
}

export default PinlogTable;
