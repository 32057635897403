import axios from 'axios';
import { getServerUrl } from '../Config';
import React, { useEffect, useState, createContext, useContext } from 'react';
import { message, Typography, Space, Button, Input } from 'antd';


async function requestLogin(userName, password) {
    const url = getServerUrl('/api/login');
    let [error, response] = await axios.post(url, {
        'user_name': userName,
        'password': password
    }, { timeout: 10000, withCredentials:true }).then(
      v => [null, v],
      err => [err, null],
    );
  
    if (error === null) {
        if (response.data.code === 0){
            message.info('login success.')
        }
    }
    
}

function EmptyPage()
{
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");

    return (<div style={{margin:24}}>
        <Input value={userName} onChange={e=>{
            setUserName(e.target.value)
        }}/>
        <Input value={password} onChange={e=>{
            setPassword(e.target.value)
        }}/>
        <Button onClick={e=>{
            requestLogin(userName, password)
        }}>OK</Button>
    </div>);
}

export default EmptyPage;