import React, { useEffect, useState, createContext, useContext } from 'react';
import { message, Typography, Space } from 'antd';
import { fetchAccessActivityRank } from '../DataModel';


const { Title, Paragraph, Text, Link } = Typography;


function RecentPage() {
  const [activityList, setActivityList] = useState([]);
  // 刷新
  const refresh = async function () {
    const [error, fetchedList] = await fetchAccessActivityRank()
    if (error === null) {
      setActivityList(fetchedList);
    }
  }
  // 定时刷新
  useEffect(() => {
    refresh();
    const taskId = setInterval(refresh, 15 * 1000);
    return () => clearInterval(taskId);
  }, [])
  // 标题
  useEffect(()=> {
    document.title = 'Pinlog - Recent';
  }, [])

  return (<div style={{ margin: 24 }}>
    <h1>Recent Activity</h1>
    <ol>
      {
        function () {
          let items = []
          for (let i = 0; i < activityList.length; ++i) {
            const one = activityList[i];

            items.push(
              <div>
                <li>
                  <a href={`edit/${one.pin_id}`} target='_blank'>
                    <Text>{one.title}</Text>
                  </a>
                  <span style={{'margin-left': 22}}/>
                  <Text type="secondary">{one.count}</Text>
                </li>
              </div>
            )
          }
          return items;
        }()
      }
    </ol>
  </div>);
}

export default RecentPage;