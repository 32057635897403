import { isFileImage } from './file_type.js';
import { getServerUrl } from '../Config';


/*
 * copy from pinlog/pinlog_edit.py
 */
export function deepCompare(a, b) {
  if (a === b)
    return true
  if (!a || !b)
    return false
  // pin
  if (!a.pin || !b.pin)
    return false
  if (a.pin.pin_id !== b.pin.pin_id)
    return false
  if (a.pin.pin_timestamp !== b.pin.pin_timestamp)
    return false
  if (a.pin.title !== b.pin.title)
    return false
  if (a.pin.abstract !== b.pin.abstract)
    return false
  if (a.pin.content !== b.pin.content)
    return false
  // log
  const a_log = a.log
  const b_log = b.log
  // 两个都是 None
  if (a_log === b_log)
    return true
  // 其中一个是None，另一个不是None
  if (!a_log || !b_log)
    return false
  // 两个都不是 None
  if (a_log.title !== b_log.title)
    return false
  if (a_log.abstract !== b_log.abstract)
    return false
  if (a_log.content !== b_log.content)
    return false

  return true
}

export function fileToImageElementText(accessPath) {
  if (isFileImage(accessPath)) {
    return `<img src="${accessPath}">`;
  } else if (accessPath.endsWith('.mp4')) {
    return `<video src="${accessPath}" controls></video>`;
  } else {
    return `<a href="${accessPath}">Wrong File Format, ${accessPath}</a>`
  }
}

export function appendLogContent(pinlog, insertText) {
  if (!insertText) {
    console.error('insert text should not be null');
    return pinlog;
  }

  let content;
  if (pinlog.log && pinlog.log.content) {
    content = pinlog.log.content + '\n' + insertText;
  } else {
    content = insertText;
  }
  const log = Object.assign({}, pinlog.log, { 'content': content });
  return Object.assign({}, pinlog, { log });
}

export function insertLogContent(pinlog, position, text) {
  if (!text) {
    console.error('insert text should not be null');
    return pinlog;
  }

  const content = pinlog.log.content;
  const newText = content.substr(0, position) + text + content.substr(position);
  const log = Object.assign({}, pinlog.log, { 'content': newText });
  return Object.assign({}, pinlog, { log });
}

const H2_PATTERN = /(?<=\n)##(.+)\n([\w\W]*?)(?=\n##)/g;
const LAST_H2_PATTERN = /(?<=\n)##(.+)\n([\w\W]*)/g;
function replacer(match, p1, p2) {

  if (p1.endsWith('<<')) {
    return `<details>
    <summary><h2> ${p1.slice(0, p1.length - 2)}
    </h2></summary>
      ${p2}
</details>

  `}
  else {
    return `<details open>
    <summary><h2> ${p1}
    </h2></summary>
      ${p2}
</details>
  
  `}

}

export function replaceH2(text) {
  let output = text.replaceAll(H2_PATTERN, replacer);
  output = output.replaceAll(LAST_H2_PATTERN, replacer);

  return output;
}


const TOP_LIST_ITEM_PATTERN = /(?<=\n- )(.+)<<\n([\w\W]*?)(?=(\n\n)|(\n-))/g;
function replaceTopListItem(match, p1, p2) {
  
  return `<details><summary> ${p1} </summary>
${p2} </details>`
}

export function foldTopListItem(text) {
  let output = text.replaceAll(TOP_LIST_ITEM_PATTERN, replaceTopListItem);

  return output;
}

function replaceSpacesWithNbsp(text) {
  // 正则表达式匹配以空格开头，后面跟着一个或多个 | 的行
  const regex = /^( |\t)+?\|/gm;

  // 使用 replace 方法和正则表达式来替换匹配到的空格
  const replacedText = text.replace(regex, function(match) {
    // 将匹配到的空格替换为 &nbsp;
    return match.replace(/ /g, '&nbsp;').replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;');
  });

  return replacedText;
}

export function applyRule(content) {
  // 图片链接替换
  const imagePrefix = getServerUrl('/download/');
  let target = content.replaceAll('src="download/', `src="${imagePrefix}`);
  // 文件链接替换
  target = target.replaceAll('href="download/', `href="${imagePrefix}`);
  // details + h2 替换
  target = replaceH2(target);
  // details + top_list_item 替换
  target = foldTopListItem(target);
  // 行前空格保留
  target = replaceSpacesWithNbsp(target);

  return target;
}