import moment from 'moment';
import React, { useEffect, useState, createContext, useContext } from 'react';
import { message, Typography, Space, Button } from 'antd';
import { fetchUploadActivity } from '../DataModel';
import { getServerUrl } from '../Config';


const { Title, Paragraph, Text, Link } = Typography;


function ImageRow(fullPath) {
  const fileExtension = fullPath.split('.').pop().toLowerCase();
  if (!['jpg', 'png'].includes(fileExtension)) {
    return
  }

  return (<div>
    <img src={fullPath} style={{ maxWidth: 200, maxHeight: 200 }} />
  </div>)
}


function UploadFilePage() {
  const [activityList, setActivityList] = useState([]);
  // 刷新
  const refresh = async function () {
    const [error, fetchedList] = await fetchUploadActivity()
    if (error === null) {
      setActivityList(fetchedList);
    }
  }
  // 定时刷新
  useEffect(() => {
    refresh();
    const taskId = setInterval(refresh, 5 * 1000);
    return () => clearInterval(taskId);
  }, [])
  // 标题
  useEffect(() => {
    document.title = 'Pinlog - Upload File';
  }, [])

  return (<div style={{ margin: 24 }}>
    <Space>
      <h1>Upload File</h1>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <Button size='small' onClick={async () => {
        await navigator.clipboard.writeText(' width=200')
        message.info("copy success.")
      }}>{'<... width>'}</Button>
      <Button size='small' onClick={async () => {
        await navigator.clipboard.writeText(' style="display:inline"')
        message.info("copy success.")
      }}>{'<... inline>'}</Button>
      <Button size='small' onClick={async () => {
        await navigator.clipboard.writeText(' width=200 style="display:inline"')
        message.info("copy success.")
      }}>{'<... width & inline>'}</Button>
    </Space>
    <ol>
      {
        function () {
          let items = []
          for (let i = 0; i < activityList.length; ++i) {
            const one = activityList[i];
            const shortUrl = one.title
            const fullUrl = getServerUrl('/' + shortUrl)

            items.push(
              <div>
                <li>
                  <a href={fullUrl} target='_blank'>
                    {one.title}
                  </a>
                  <span style={{ 'margin-left': 22 }} />
                  <Space>
                    <Text type="secondary">{moment(one.timestamp * 1000).format('YYYY-MM-DD, HH:mm:ss')}</Text>
                    <Button size='small' onClick={async () => {
                      await navigator.clipboard.writeText(`<img src="${shortUrl}">`)
                      message.info("copy success.")
                    }}>{'<img...>'}</Button>
                    <Button size='small' onClick={async () => {
                      await navigator.clipboard.writeText(`<a href="${shortUrl}" target="_blank">${shortUrl.split('/').pop()}</a>`)
                      message.info("copy success.")
                    }}>{'<a...>'}</Button>
                    <Button size='small' onClick={async () => {
                      await navigator.clipboard.writeText(`<a href="${shortUrl}" target="_blank">🦋原文备份</a>`)
                      message.info("copy success.")
                    }}>{'🦋原文备份'}</Button>
                  </Space>
                  {/* 假如是图片 */}
                  {ImageRow(fullUrl)}
                </li>
              </div>
            )
          }
          return items;
        }()
      }
    </ol>
  </div>);
}

export default UploadFilePage;