import React from 'react';


function EmptyPage()
{
    return (<div style={{margin:24}}>
        <h1>Empty Page</h1>
        <p>this is a page for testing.</p>
    </div>);
}

export default EmptyPage;